<template>
  <div>
    <div class="uploadConiter">
      <div v-if="fileType === 'image'">
        <el-upload
          action=""
          accept=".jpg,.png"
          :limit="1"
          :auto-upload="false"
          :file-list="fileList"
          list-type="picture-card"
          :on-remove="handleRemove"
          :on-change="handleChange"
          :http-request="uploadFileEvent"
          :on-preview="handlePictureCardPreview"
          :class="objClass"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog append-to-body :visible.sync="dialogVisible" width="500px">
          <img :src="dialogImageUrl" alt="" class="preview_img" />
        </el-dialog>
      </div>
      <div v-if="fileType === 'fileWord'">
        <el-upload
          action=""
          accept=".doc,.xls,.pdf,.docx,.xlsx"
          :limit="3"
          multiple
          :auto-upload="false"
          :file-list="wordList"
          :on-remove="handleRemove"
          :on-change="handleChange"
          :http-request="uploadFileEvent"
          :on-preview="handlePdfPreview"
        >
          <el-button size="small" type="primary">点击上传</el-button>
        </el-upload>
      </div>
      
      <div v-if="fileType === undefined">
        <el-upload
          class="upload-demo"
          action=""
          multiple
          accept=".mp4"
          :on-exceed="handleExceed"
          :file-list="vedioList"
          :http-request="uploadFileEvent"
        >
          <el-button size="small" type="primary" icon="el-icon-upload2"
            >点击上传</el-button
          >
        </el-upload>
      </div>
    </div>
  </div>
</template>
<script>
import { enterpriseUpload } from "@api/enterprise";
export default {
  name: "uploadfile",
  // inject: ["newData"],
  data() {
    return {
      dialogVisible: false,
      dialogImageUrl: "",
      vedioList: [],
      fileList: [],
      wordList: [],
      objClass: {
        upLoadShow: true,
        upLoadHide: false,
      },
    };
  },
  props: {
    fileType: {
      type: String,
    },
    reserveData: {
      type: Object,
    },
    rowId: {
      type: Number,
    },
  },
  created() {},
  mounted() {
    // console.log('打印接收的值', this.reserveData)
    this.fileList = [];
  },
  methods: {
    handleChange(file, fileList) {
      // console.log(file,'文件信息')
      //隐藏
      this.objClass.upLoadHide = true;
      this.objClass.upLoadShow = false;
      this.uploadFileEvent(file, "image");
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handlePdfPreview(file) {
      if (this.rowId) {
        window.open(file.url);
      } else {
        return;
      }
    },
    handleRemove() {
      //显示
      this.objClass.upLoadHide = false;
      this.objClass.upLoadShow = true;
    },
    handleExceed(file, fileList) {
      console.log(file, fileList);
    },
    // preUpload() {

    // },
    //上传请求接口
    uploadFileEvent(file, type) {
      // console.log(file.file);
      const formData = new FormData();
      // 向 formData 对象中添加文件
      if (type === "image") {
        //上传图片
        formData.append("file", file.raw);
      } else if (type === undefined) {
        //上传视频
        formData.append("file", file.file);
      }
      // console.log(type, '文件视频')
      enterpriseUpload(formData)
        .then((res) => {
          // console.log("打印返回的数据", res);
          if (res.code == 200) {
            this.$message({
              message: "上传成功",
              type: "success",
            });
          }
          if (type === undefined) {
            this.vedioList.push({ name: res.data.name, url: res.data.url });
          } else if (type === "image") {
            this.vedioList = [];
            this.fileList.push({ name: res.data.name, url: res.data.url });
            // console.log('文件数组', this.fileList)
          }

          let fileData = {
            photo: res.data.url,
            files: this.vedioList,
          };
          // fileData = Object.assign({}, res.data);
          // fileData.files = this.vedioList; //把视频文件加进去
          this.$emit("reserve-data", fileData);
          //传给标准文件的
          res.fileList = this.fileList;
          let standardFiles = { ...res };
          this.$emit("standard-data", standardFiles);
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
  },
};
</script>
<style scoped>
.el-dialog__body .preview_img {
  display: block;
  width: 220px;
  height: 220px;
  margin: 0 auto;
}

/deep/ .el-upload .el-upload-dragger {
  width: 80%;
  height: 80%;
}

/deep/ .upLoadShow .el-upload {
  width: 108px;
  height: 108px;
  line-height: 106px;
}

/deep/ .upLoadHide .el-upload-list--picture-card .el-upload-list__item {
  width: 108px;
  height: 108px;
  line-height: 106px;
}

/deep/ .upLoadHide .el-upload {
  display: none !important;
}
</style>
<style scoped lang="scss">
.uploadConiter {
}
</style>
