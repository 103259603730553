<template>
  <div class="standards">
    <el-form>
      <div class="head_form">
        <div>
          <el-form-item label="项目名称">
            <el-input v-model="formModal.name" placeholder="请输入项目名称" clearable
              @keyup.enter.native="queryStandard(this.limit)"></el-input>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="分类">
            <el-select v-model="formModal.type" clearable placeholder="请选择分类">
              <el-option v-for="item in selectOptions" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="标签">
            <el-select v-model="label_tag" placeholder="标签检索" clearable multiple @focus="labelDataList()"
              @change="markItemChange">
              <el-option v-for="(item, index) in labelList" :key="index" :value="item.name"></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="right_area">
          <el-button type="primary" @click="queryStandard(this.limit)">查询</el-button>
          <el-button type="info" @click="qingkong('formModal')">清空</el-button>
        </div>
      </div>
    </el-form>
    <div class="plus_add">
      <el-button type="primary" icon="el-icon-plus" @click="operateFrom()">新增</el-button>
      <el-button type="primary" icon="el-icon-download" @click="exportExcel()" style="float: right;">导出标准清单</el-button>
    </div>
    <div>
      <el-table :data="standardData" :row-class-name="tabRowClassName" style="width: 100%"
        :header-cell-style="{ background: '#3082E2', color: '#FFF' }">
        <!-- <el-table-column label="ID" prop="id" align="center" width="100px">
        </el-table-column> -->
        <el-table-column label="排序" prop="sort" align="center" width="100px">
        </el-table-column>
        <el-table-column label="认证号" prop="certifiedid" align="center" width="260px">
        </el-table-column>
        <el-table-column label="名称" prop="name" align="left">
          <template slot-scope="{ row }">
            <el-tag size="mini" v-if="row.type == 1" type="success"
              style="background-color:blueviolet;color:white">制定</el-tag>
            <el-tag size="mini" v-if="row.type == 2" type="warning"
              style="background-color:blue;color:white">修订</el-tag>
            <span class="subtext" @click="infoEvent(row)">{{ row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="编制企业" prop="company" align="center" width="260px">
        </el-table-column>
        <el-table-column label="标签" prop="batch" align="center" width="150px">
          <template slot-scope="{ row }">
            <div class="label_list">
              <div v-for="(item, index) in row.new_label" :key="index" class="btns">
                <el-tag v-if="item.label != null" size="mini" style="color: white;background-color:black">{{ item.label
                  }}</el-tag>
              </div>
            </div>
          </template>
        </el-table-column>

        <!-- <el-table-column label="类型" prop="id" align="center" width="100">
          <template slot-scope="{ row }">
            <span v-if="row.type == 1">制定/企标</span>
            <span v-if="row.type == 2">修订/企标</span>
            <span v-if="row.type == 3">制定/省标</span>
            <span v-if="row.type == 4"> 修订/省标</span>
          </template>
        </el-table-column> -->
        <!-- <el-table-column label="创建时间" prop="created_at" align="center" width="180px">
        </el-table-column> -->
        <el-table-column label="操作" align="center" width="200px">
          <template slot-scope="{ row }">
            <el-tooltip class="item" effect="dark" content="编辑" placement="top">
              <el-button icon="el-icon-edit" type="text" @click="operateFrom(row)"></el-button>
            </el-tooltip>
            <el-tooltip class="item del_icon" effect="dark" content="删除" placement="top">
              <el-button icon="el-icon-delete" type="text" style="color: red;" @click="handleDelete(row)"></el-button>
            </el-tooltip>
            <el-tooltip class="item del_icon" effect="dark" content="添加标签" placement="top"><el-button
                @click="addLabel(row.id)" type="text" icon="el-icon-plus"></el-button></el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <div class="pageTotal">
      <div class="block">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="page" layout="prev, pager, next" :total="totalCount" :page-size="limit">
        </el-pagination>
      </div>
    </div>
    <div>
      <el-dialog :visible.sync="operateVisible" custom-class="operate_dialog" :close-on-click-modal="false"
        :title="titleName" @close="infoCancle()">
        <el-form :model="editForm" ref="editForm" class="stand_form">
          <div class="form_line">
            <el-form-item label="类型" prop="type" :rules="[{ required: true, message: '请选择类型', trigger: 'blur' }]">
              <el-select v-model="editForm.type" clearable>
                <el-option v-for="item in selectOptions" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="标准名称" prop="name" :rules="[{ required: true, message: '请输入标准名称', trigger: 'blur' }]">
              <el-input v-model="editForm.name"></el-input>
            </el-form-item>
            <el-form-item label="单位名称" prop="company"
              :rules="[{ required: true, message: '请输入单位名称', trigger: 'blur' }]">
              <el-input v-model="editForm.company"></el-input>
            </el-form-item>
            <el-form-item label="认证号" prop="certifiedid"
              :rules="[{ required: true, message: '请输入认证号', trigger: 'blur' }]">
              <el-input v-model="editForm.certifiedid"></el-input>
            </el-form-item>
            <el-form-item label="公告号" prop="noticeid" :rules="[{ required: true, message: '请输入公告号', trigger: 'blur' }]">
              <el-input v-model="editForm.noticeid"></el-input>
            </el-form-item>
            <el-form-item label="公告有效期" prop="noticeid"
              :rules="[{ required: true, message: '请输入公告有效期', trigger: 'blur' }]">
              <el-input v-model="editForm.publish_state"></el-input>
            </el-form-item>
            <el-form-item>
              <el-upload class="upload-demo" action="https://api.gbservice.cn/api/public/enterpriseUpload"
                :on-change="handleChange" :file-list="fileList" :on-remove="handleRemove" :before-remove="beforeRemove">
                <el-button size="small" type="primary">点击上传</el-button>
                <div slot="tip" class="el-upload__tip">只能上传pdf文件，且不超过40Mb</div>
              </el-upload>
            </el-form-item>
          </div>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="infoCancle()">取 消</el-button>
          <el-button type="primary" @click="levelSubmit('editForm')">提 交</el-button>
        </div>
      </el-dialog>
    </div>
    <div>
      <el-dialog :visible.sync="label_show" title="新增标签" width="30%">
        <el-form>
          <el-form-item label="标签名称" class="label_input">
            <el-select v-model="labelSubitem" placeholder="标签检索" clearable multiple @focus="labelDataList()"
              @change="markItemChange">
              <el-option v-for="(item, index) in labelList" :key="index" :value="item.name"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="label_show = false">取 消</el-button>
          <el-button type="primary" @click="label_sure">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import {
  standardsData,
  standardAdd,
  standardInfo,
  standardDel,
  standardModify,
  labelSelectData,
  enterpriseNewAdd,
} from "@api/enterprise";
import uploadFile from "../components/uploadFile.vue";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
export default {
  name: "standardfile",
  // inject: ["newData"],
  components: { uploadFile },
  data() {
    return {
      fileList: [],
      formModal: {
        name: "",
        type: null,
      },
      selectOptions: [
        {
          label: "制定/企标",
          value: 1,
        },
        {
          label: "修订/企标",
          value: 2,
        },
      ],
      standardData: [],
      page: 1,
      limit: 15,
      totalCount: 0,
      editForm: {
        name: "",
        noticeid: "",
        certifiedid: "",
        company: "",
        type: "",
      },
      operateVisible: false,
      fileType: "fileWord",
      fileObj: null,
      titleName: "新增",
      label_show: false,
      labelSubitem: [],
      labelList: [],
      markIds: [],
      // label: "",
      label_tag: ""
    };
  },
  props: {},
  mounted() {
    this.queryStandard(this.limit);
    this.labelDataList();
  },
  methods: {
    // 导出表格
    exportExcel() {

      this.$confirm("是否确认导出全部标准数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(response => {

        let params = {
          token: this.$store.state.token,
          page: this.page,
          limit: 1000,
          name: this.formModal.name == "" ? undefined : this.formModal.name,
          type: this.formModal.type == null ? "1,2" : this.formModal.type,
          label: this.label_tag,
          status: 1,
        };
        standardsData(params).then((rec) => {
          this.exportList(rec.data);
        });

      })
    },
    exportList(arr) {
      let tableData = [
        { id: '序号', certifiedid: '公示号', noticeid: '认证号', name: '名称', type: '类型', company: '编制企业' }//导出表头
      ] // 表格表头

      arr.forEach((item, index) => {
        let rowData = []
        //导出内容的字段
        rowData = {
          id: item.id,
          noticeid: item.noticeid,
          certifiedid: item.certifiedid,
          name: item.name,
          type: item.type == 1 ? "制定" : "修订",
          company: item.company
          // item.new_label.join(',')       
        }
        tableData.push(rowData);
      })
    
      
      let workSheet = XLSX.utils.json_to_sheet(tableData);
      let bookNew = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(bookNew, workSheet, '全部标准'); // 工作簿名称
      let name = '全部标准.xlsx';
      const wbout = XLSX.write(bookNew, { bookType: 'xlsx', type: 'array' });
      // XLSX.writeFile(bookNew, name); // 保存的文件名
      saveAs(new Blob([wbout], { type: 'application/octet-stream' }), name);
    },
    // 移除文件提示
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    // 移除文件
    handleRemove(file, fileList) {
      // console.log("移除文件", file, fileList);
      this.editForm.files = "";

    },
    handleChange(file, fileList) {
      // console.log("上传文件",fileList[0].response);
      // this.fileList = fileList.slice(-3);
      this.fileObj = fileList[0].response;
      fileList = [];
    },
    //标准文本
    queryStandard(limit) {
      let params = {
        token: this.$store.state.token,
        page: this.page,
        limit: limit,
        name: this.formModal.name == "" ? undefined : this.formModal.name,
        type: this.formModal.type == null ? "1,2" : this.formModal.type,
        label: this.label_tag,
        status: 1,
      };
      standardsData(params).then((rec) => {
        // console.log('标准文本', rec)
        this.standardData = rec.data;
        this.page = rec.page; //当前页
        this.totalCount = rec.totalCount;
        this.totalPage = rec.totalPage;
        this.limit = rec.limit;
        return rec.data;
      });
    },

    qingkong() {
      this.formModal.name = "";
      this.formModal.type = "";
      this.label_tag = "";
      this.queryStandard(this.limit);
    },
    handleUpdate() { },
    handleDelete(row) {
      this.$confirm("是否确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let data = {
            id: row.id,
            token: this.$store.state.token,
          };
          standardDel(data).then((rec) => {
            // console.log("返回删除的结果", rec);
            if (rec.code == 200) {
              this.$message({
                type: "success",
                message: rec.message,
              });
              this.formModal.name = "";
              this.formModal.type = "";
              this.queryStandard(this.limit);
            }
          });
        })
        .catch(() => { });
    },
    tabRowClassName({ rowIndex }) {
      let index = rowIndex + 1;
      if (index % 2 == 0) {
        return "warning-row";
      }
    },
    handleSizeChange(page) {
      this.limit = page;
      this.queryStandard(this.limit);
    },
    handleCurrentChange(page) {
      this.page = page;
      this.queryStandard(this.limit);
    },
    //新增或修改
    operateFrom(row) {
      // console.log(row, '打印行信息')
      let that = this;
      that.titleName = "新增";
      that.$nextTick(() => {
        if (that.$refs.uploadWord) {
          that.$refs.uploadWord.wordList = [];
        }
      });
      if (row != undefined) {
        that.titleName = "修改";
        //回显
        that.queryStandardInfo(row);
        that.rowId = row;
      }
      that.operateVisible = true;
    },
    infoEvent(row) {
      // console.log(row, '打印行信息')
      window.open(row.files[0].url);
    },
    infoCancle() {
      this.operateVisible = false;
      this.$refs.editForm.resetFields();
    },
    // 回显修改内容
    queryStandardInfo(row) {
      let params = {
        id: row.id,
        token: this.$store.state.token,
      };
      standardInfo(params).then((rec) => {
        // console.log('打印回显的内容', rec)
        this.editForm = { ...rec.data };
        this.fileList = rec.data.files;
        // this.$refs.uploadWord.wordList = rec.data.files;
      });
    },
    //提交
    levelSubmit(formName) {
      // console.log("打印表单的对象:", this.editForm);
      this.formModal.name = "";
      this.formModal.type = "";
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let file_name = "";
          let url = "";
          if (this.fileObj == null && this.editForm.id == undefined) {
            //新增才提示
            this.$message({
              type: "warning",
              message: "文件不能为空",
            });
            return false;
          } else if (this.fileObj != null) {
            file_name = this.fileObj.data.name;
            url = this.fileObj.data.url;
          }
          if (this.editForm.id === undefined) {
            //新增
            let data = {
              ...this.editForm,
              file_name: file_name,
              url: url,
              token: this.$store.state.token,
            };
            standardAdd(data).then((rec) => {             
              if (rec.code == 200) {
                this.$message({
                  type: "success",
                  message: rec.message,
                });
                this.queryStandard(this.limit);
              }
            });
          } else if (this.editForm.id != undefined) {
            //修改
            let data = {}
            data = {
              ...this.editForm,
              file_name: this.editForm.files[0].name,
              url: this.editForm.files[0].url,
              token: this.$store.state.token,
            };            
            standardModify(data).then((rec) => {
              // console.log('返回暂存的结果', rec)
              if (rec.code == 200) {
                this.$message({
                  type: "success",
                  message: rec.message,
                });
                this.queryStandard(this.limit);
              }
            });
          }
          this.operateVisible = false;
        } else {
          //   console.log("error submit!!");
          return false;
        }
      });
    },
    markItemChange(val) {
      let tagList = val;
      var filterdata = this.labelList.filter((item) =>
        tagList.some((x) => x === item.name)
      );
      // console.log('打印过滤的数据', filterdata)
      this.markIds = filterdata.map((val) => val.id);
    },
    //查询标签数据
    labelDataList() {
      labelSelectData({ token: this.$store.state.token }).then((rec) => {        
        this.labelList = rec.data.filter((item) => item.name != null);
      });
    },
    addLabel(rowID) {
      this.label_show = true;
      this.rowID = rowID;
      this.labelSubitem = [];
    },
    //新增标签确认
    label_sure() {
      let params = {
        id: this.rowID,
        token: this.$store.state.token,
        label: this.markIds,
      };
      enterpriseNewAdd(params).then((rec) => {        
        if (rec.code == 200) {
          this.$message({
            showClose: true,
            message: rec.message,
            type: "success",
          });          
          this.label_show = false;
          this.queryStandard(this.limit);
        }
      });
      // }
    },
  },
};
</script>
<style scoped>
/deep/ .litop .el-input {
  width: 245px;
}

/deep/ .litop .el-input__inner {
  width: 204px;
}

.head_form {
  background: #fff;
  padding: 18px 24px;
  display: flex;
}

.head_form .el-form-item {
  display: flex;
  margin-right: 30px;
}

.pageTotal {
  display: flex;
  flex-direction: row-reverse;
  margin: 30px 0;
}

.standards .del_icon {
  margin-left: 20px;
}

.plus_add {
  margin: 20px 0 20px 0;
}

.form_line {}

.form_line .el-form-item {
  width: 70%;
}

/deep/ .operate_dialog {
  width: 700px;
}

/deep/ .stand_form .el-form-item__content {
  display: flex;
}

.operate_dialog .el-radio-group {
  margin-top: 11px;
  margin-left: 24px;
}

/deep/ .stand_form .el-form-item__label {
  width: 100px;
}

.standards .subtext {
  color: #0099fc;
  cursor: pointer;
}
</style>
